import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import config from '../config/environment';
import Controller from '@ember/controller';

export default Controller.extend({
  config,
  cms: service(),
  datoPageBlockData: computed('cms.currentPageData', function () {
    return this.cms.currentPageData.blocks.reduce((out, currentValue) => {
      let item;

      switch (currentValue.slug) {
        case 'domains':
          item = {
            prices: currentValue.content.split('\n').map(row => {
              const prices = row.split('|');

              return {
                tld: prices[0],
                defaultPrice: prices[1],
                defaultRenewalPrice: prices[2],
                defaultTransferPrice: prices[3]
              };
            }),
            subtitle: currentValue.subtitle,
            title: currentValue.title
          };
          break;

        // eslint-disable-next-line no-case-declarations
        case 'emails':
          const emailTypes = currentValue.content.split('\n');

          const personalData = emailTypes[0].split('|');
          const exchangeOnlineData = emailTypes[1].split('|');

          item = {
            types: {
              personal: {
                threeMonths: personalData[1],
                year: personalData[2],
                title: personalData[0]
              },
              exchangeOnline: {
                month: exchangeOnlineData[1],
                year: exchangeOnlineData[2],
                title: exchangeOnlineData[0]
              }
            },
            subtitle: currentValue.subtitle,
            title: currentValue.title
          };
          break;

        // eslint-disable-next-line no-case-declarations
        case 'microsoft-365':
          const microsoft365Types = currentValue.content.split('\n');

          const businessStandardData = microsoft365Types[0].split('|');
          const businessBasic = microsoft365Types[1].split('|');

          item = {
            types: {
              businessStandard: {
                month: businessStandardData[1],
                year: businessStandardData[2],
                title: businessStandardData[0]
              },
              businessBasic: {
                month: businessBasic[1],
                year: businessBasic[2],
                title: businessBasic[0]
              }
            },
            subtitle: currentValue.subtitle,
            title: currentValue.title
          };
          break;

        case 'hosting':
          item = {
            prices: currentValue.content.split('\n').map(row => {
              const prices = row.split('|');

              return {
                name: prices[0],
                month: prices[1],
                year: prices[2],
                threeYears: prices[3]
              };
            }),
            subtitle: currentValue.subtitle,
            title: currentValue.title
          };
          break;

        case 'wordpress-hosting':
          item = {
            prices: currentValue.content.split('\n').map(row => {
              const prices = row.split('|');

              return {
                name: prices[0],
                month: prices[1],
                year: prices[2],
                threeYears: prices[3]
              };
            }),
            subtitle: currentValue.subtitle,
            title: currentValue.title
          };
          break;

        case 'ssl-certificates':
          item = {
            prices: currentValue.content.split('\n').map(row => {
              const prices = row.split('|');

              return {
                name: prices[0],
                oneYear: prices[1],
                twoYear: prices[2]
              };
            }),
            subtitle: currentValue.subtitle,
            title: currentValue.title
          };
          break;

        case 'misc':
          item = {
            prices: currentValue.content.split('\n').map(row => {
              const prices = row.split('|');

              return {
                name: prices[0],
                price: prices[1]
              };
            }),
            subtitle: currentValue.subtitle,
            title: currentValue.title
          };
          break;
      }

      return {
        ...out,
        [currentValue.slug]: item
      };
    }, {});
  })
});
