import Service, { inject as service } from '@ember/service';

import config from '../config/environment';
import Logger from '../utils/logger';
import cookiesParser from '../utils/cookies-parser';

const cookies = cookiesParser();

const lastBasketHash = cookies && cookies.basket_hash;

export default Service.extend({
  cart: service(),
  store: service(),
  currentUser: service(),
  cartItems() {
    return (this.get('cart.items') || []).map(cartItem => cartItem.attrs());
  },

  async loadSaved() {
    if (!lastBasketHash) return [];
    const basket = await this.store.findRecord('basket', lastBasketHash);
    if (basket) {
      return basket.cartItems.map(cartItem => cartItem.parseAttrs());
    }
  },

  storeNew() {
    const basket = this.store.createRecord('basket', {
      currency: this.get('currentUser.currency'),
      key: 'current',
      lang: config.LOCALE,
      order: {
        coupon: this.cart?.coupon,
        customer: {},
        items: this.cartItems()
      }
    });
    return basket.save().then(basketData => {
      document.cookie = `basket_hash=${basketData.hash}; path=/`;
      return basketData;
    });
  },
  async load() {
    try {
      const basket = await this.store.findRecord('basket', 'current');
      return basket.cartItems.map(cartItem => cartItem.parseAttrs());
    } catch (error) {
      this.storeNew();
      throw error;
    }
  },
  async save() {
    const basket = this.store.peekAll('basket').findBy('key', 'current');

    if (!basket) {
      return this.storeNew();
    } else {
      basket.set('lang', config.LOCALE);
      basket.set('currency', this.get('currentUser.currency'));
      basket.set('order', {
        customer: {},
        coupon: this.cart?.coupon,
        items: this.cartItems()
      });
      return basket.save();
    }
  },
  async delete() {
    const baskets = this.store.peekAll('basket').filterBy('key', 'current');
    return Promise.all(
      baskets.map(basketItem => {
        // destroy nejde, uz neni session
        // return basketItem.destroyRecord().then(() => {
        return basketItem.unloadRecord();
        // });
      })
    ).catch(Logger.error);
  }
});
