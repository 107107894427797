import config from '../config/environment';
const Console = (window && window.console) || console;

const consoleObject = {};
if (config.environment === 'development' && Console) {
  consoleObject['log'] = Console.log;
  consoleObject['debug'] = Console.debug;
  consoleObject['error'] = Console.error;
  consoleObject['warn'] = Console.warn;
} else {
  consoleObject['log'] = () => {};
  consoleObject['debug'] = () => {};
  consoleObject['error'] = Console.error;
  consoleObject['warn'] = () => {};
}

export default consoleObject;
